<template>
  <div class="container">
    <Van-Navbar :title="lang.route_comment">
      <template #left>
        <van-icon name="arrow-left" size="18" @click="back" />
      </template>
    </Van-Navbar>
    <van-tabs
      :class="['contentTabs', language == 'en' ? 'en_tab' : '']"
      :active="activeTabs"
      :before-change="beforeChange"
    >
      <van-tab
        v-for="(item, index) in courseType"
        :key="index"
        :title="lang[item.lang]"
      ></van-tab>
    </van-tabs>
    <router-view class="content" style="flex: 1" />
    <!-- <keep-alive>
      <router-view />
    </keep-alive> -->
  </div>
</template>
  
  <script>
import { reactive, toRefs, ref } from "vue";
import Common from "@/library/Common";
import { useRouter } from "vue-router";
import VanNavbar from "@/components/common/van-navbar";

export default {
  name: "ClassEvaluation",
  components: {
    VanNavbar,
  },
  setup() {
    const common = Common;
    const router = useRouter();
    const courseData = router.currentRoute.value.query.courseData;
    let state = reactive({
      lang: {
        route_comment: "",
        langBtn: "",
        tab_personreview: "",
        tab_class: "",
        tab_Homework: "",
      },
      activeTabs: 0,
      courseType: [
        {
          name: "个人点评",
          value: 1,
          lang: "tab_personreview",
          path: "/comment/student",
        },
        {
          name: "班级点评",
          value: 2,
          lang: "tab_class",
          path: "/comment/class",
        },
        {
          name: "上传作业",
          value: 3,
          lang: "tab_Homework",
          path: "/comment/work",
        },
      ],
      language: common.getLocal("Language"),
    });
    common.initLanguage(state.lang);
    state.activeTabs = router.currentRoute.value.meta.active;
    const beforeChange = (index) => {
      state.activeTabs = index;
      router.push({
        path: state.courseType[index].path,
        query: {
          courseData: courseData,
        },
      });
    };
    const back = () => {
      router.push({
        path: JSON.parse(courseData)["currentPath"],
      });
    };
    return {
      ...toRefs(state),
      common,
      back,
      beforeChange,
    };
  },
  created() {
    console.log("created", this.$route);
  },
};
</script>
  
<style lang="less" scoped>
.content {
  flex: 1;
  overflow-y: auto;
}
.en_tab {
  /deep/.van-tab {
    width: auto;
    font-size: 24px;
    line-height: 32px;
    flex: content;
  }
  /deep/.van-tab__text--ellipsis {
    -webkit-line-clamp: 2;
  }
}
</style>